import React from "react";
import Hero from "pages/Hero";
import GlobalStyle from "styles/GlobalStyle";
import SEO from "components/SEO";

const IndexPage = () => {
  return (
    <>
      <SEO />
      <GlobalStyle />
      <main>
        <Hero />
      </main>
    </>
  );
};
export default IndexPage;
