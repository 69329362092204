import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  :root {
    --firefly: #0B302E;
    --dark-navy: #060b19;
    --carribean-green: #04E390;
    --salem: #077A55;
    --observatory: #05946C;
    --white: #ffffff;
    --carribean-green-darker: #375348;

    --font-sans: 'InknutAntiqua', sans-serif;
    --font-mono: 'Inconsolata', monospace;

    --transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  html {
    box-sizing: border-box;
    width: 100%;
    font-family: var(--font-mono);
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    width: 100%;
    min-height: 100%;
    overflow-x: hidden;
    background-color: var(--dark-navy);
  }

  main {
    margin: 0 auto;
    width: 100%;
    max-width: 800px;
    min-height: 100vh;
    padding: 0 20px;
  }

  section {
    margin: 0 auto;
    padding: 100px 0;
    max-width: 1000px;
  }

  p {
    margin: 15px 0;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 10px 0;
    font-weight: 700;
    line-height: 1.1;
  }

  svg {
    width: 100%;
    height: 100%;
    fill: currentColor;
    vertical-align: middle;
    transition: var(--transition);

    &.feather {
      fill: var(--carribean-green-darker);
      &:hover {
        fill: var(--carribean-green);
      }
    }
  }
`;

export default GlobalStyle;
